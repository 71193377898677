/**
 * Hamburger menu (sp)
 */

const hamburger = () => {
  const header = document.querySelector('.header') as any;
  const menu = document.querySelector('.js-menu') as any;
  const nav = document.querySelector('.js-nav') as any;
  const targets = [menu];
  const windowWidth = window.innerWidth;

  // toggle nav when a.js-nav-link-top is clicked (only when current page is Top)
  const isTopPage = document.querySelector('.js-top-page');
  if ( isTopPage ) {
    const navLinksTop = document.querySelectorAll('.js-nav-link-top') as NodeList;
    targets.push(...navLinksTop);
  }

  if ( header && menu && nav) {
    targets.forEach(target => {
      target.addEventListener('click', function() {
        menu.classList.toggle('active');
        nav.classList.toggle('active');

        // prevent scrolling when nav is opened (SP)
        if( windowWidth <= 767 ) {
          document.body.classList.toggle('overflow');
        }
      });
    })
  };
};

export default hamburger