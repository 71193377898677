/**
 * Animate on scroll init
 */
// @ts-ignore
import AOS from 'aos'

const aos = () => {
  AOS.init({
    offset: screen.width < 768 ? 20 : 120,
    duration: 1000,
    anchorPlacement: 'top-top',
  })
}

export default aos