import aos from './components/aos'
import hamburger from './components/hamburger'

document.addEventListener(
  'DOMContentLoaded',
  () => {
    hamburger()
    aos()
  },
  false
)
